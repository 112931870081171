import {
  Box,
  Button,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  FormField,
  ToggleSwitch,
  CircularProgressBar,
  Tabs,
  CardGalleryItem,
  Badge,
  Layout,
  Cell,
  Tooltip,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const meta: any = {
  CursorShapes: {
    Sparkles: [
      "goldSparkles",
      "silverSparkles",
      "colorSparkles",
      "blueSparkles",
    ],
    Glitter: ["glitter", "colorGlitter"],
    Stars: [
      "goldStars",
      "silverStars",
      "colorStars",
      "blueStars",
      "blueStars2",
      "goldStars2",
    ],
    Rain: [
      "goldRain",
      "silverRain",
      "colorRain",
      "blueRain",
      "goldDrops",
      "silverDrops",
      "blueDrops",
      "colorDrops",
    ],
    Autumn: ["leaves", "flowers", "spiral", "goldFeather"],
    Winter: [
      "flakes1",
      "flakes2",
      "colorXmas",
      "blueXmas",
      "goldXmas",
      "snow1",
      "snow2",
      "snow3",
    ],
    Party: ["confetti1", "confetti2", "party", "eParty"],
    Love: ["idealLove", "love", "goldLove", "springLove"],
  },
  AnimationTypes: {
    Default: ["flash"],
    Rotations: ["rotation90", "rotation90n", "rotation360", "rotation360n"],
    ShakeAndSwing: ["shake", "shakeX", "shakeY", "swing"],
    Directions: [
      "down",
      "downScale",
      "downRotate1",
      "downRotate2",
      "leftDownScale",
      "rightDownScale",
      "leftDown",
      "rightDown",
      "up",
    ],
    Wind: ["wind", "storm", "gale", "gust"],
  },
  BestCombinations: {
    Rain: [
      "down",
      "downScale",
      "downRotate1",
      "downRotate2",
      "leftDownScale",
      "rightDownScale",
      "leftDown",
      "rightDown",
      "up",
    ],
    Autumn: ["wind", "storm", "gale", "gust"],
    Winter: [
      "flash",
      "rotation90",
      "rotation90n",
      "rotation360",
      "rotation360n",
      "down",
      "leftDown",
      "rightDown",
      "wind",
      "storm",
      "gale",
      "gust",
    ],
    Party: [
      "flash",
      "rotation90",
      "rotation90n",
      "rotation360",
      "rotation360n",
      "shake",
      "shakeX",
      "shakeY",
      "swing",
      "down",
      "leftDown",
      "rightDown",
      "up",
    ],
    Love: [
      "flash",
      "rotation90",
      "rotation90n",
      "rotation360",
      "rotation360n",
      "shake",
      "shakeX",
      "shakeY",
      "swing",
      "down",
      "leftDown",
      "rightDown",
      "up",
    ],
  },
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [selectedEffectType, setSelectedEffectType] = React.useState(0);
  const [selectedEffect, setSelectedEffect] = React.useState("");
  const [selectedAnimationType, setSelectedAnimationType] = React.useState(0);
  const [selectedAnimation, setSelectedAnimation] = React.useState("");

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  useEffect(() => {
    if (!isLoading && selectedEffect && selectedAnimation) {
      setSettings();
    } else {
      setIsLoading(false);
    }
  }, [isEnabled, selectedEffect, selectedAnimation]);

  useEffect(() => {
    if (selectedEffect && selectedAnimation) {
      try {
        window.cursorTrail({
          pattern: selectedEffect,
          animationType: selectedAnimation,
          theme: "light", // dark, light
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedEffect, selectedAnimation]);

  const BASE_URL = `https://certifiedcode.wixsite.com/cursor-trail/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function getSettings() {
    setIsLoading(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSelectedAnimation(data?.options?.animationType);
        setSelectedEffect(data?.options?.pattern);
        if (data?.options?.animationType)
          setSelectedAnimationType(
            Object.keys(meta.AnimationTypes).findIndex((key) =>
              meta.AnimationTypes[key].includes(data.options.animationType)
            )
          );
        if (data?.options?.pattern)
          setSelectedEffectType(
            Object.keys(meta.CursorShapes).findIndex((key) =>
              meta.CursorShapes[key].includes(data.options.pattern)
            )
          );

        setIsEnabled(data.isEnabled);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function setSettings() {
    setIsLoading(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: isEnabled,
        options: {
          pattern: selectedEffect,
          animationType: selectedAnimation,
          theme: "light",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getImageUrl(key: string) {
    // it is from src/paterns/NAME.svg. E.g. blue-drop. Needs to convert from snake case to all lowercase with hyphen
    let slug = key.replace(/([A-Z])/g, "-$1").toLowerCase();
    // remove ending 's' if it is the last character
    slug = slug.replace(/s$/, "");
    if (key === "blueStars2") {
      slug = "blue-moon";
    }
    if (key === "goldStars2") {
      slug = "gold-moon";
    }
    if (key === "flakes1") {
      slug = "flake-1";
    }
    if (key === "flakes2") {
      slug = "flake-2";
    }
    if (key === "colorXmas") {
      slug = "xmas-ball";
    }
    if (key === "blueXmas") {
      slug = "xmas-ball-2";
    }
    if (key === "goldXmas") {
      slug = "xmas-ball-3";
    }
    if (key === "snow1") {
      slug = "white-snow";
    }
    if (key === "snow2") {
      slug = "blue-snow";
    }
    if (key === "snow3") {
      slug = "gold-snow";
    }
    if (key === "confetti1") {
      slug = "confetti";
    }
    if (key === "confetti2") {
      slug = "confetti-2";
    }
    if (key === "party") {
      slug = "ice-cream";
    }
    if (key === "idealLove") {
      slug = "heart-balloon";
    }
    if (key === "love") {
      slug = "heart-red";
    }
    if (key === "goldLove") {
      slug = "heart-gold";
    }
    if (key === "springLove") {
      slug = "flower";
    }
    return `/patterns/${slug}.svg`;
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=fd5f8619-81d6-44e0-ad18-46599d8f3d52&redirectUrl=https://certifiedcode.wixsite.com/cursor-trail/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Cursor Trail: Cool Effects"
                // content="cursor-trail is a WCAG accessibility tool that helps you make your website more accessible to people with disabilities."
                actions={
                  <Box direction="vertical" gap="SP1">
                    <FormField
                      label="Enable Cursor Trail"
                      labelPlacement="left"
                    >
                      <Tooltip
                        content={
                          isLoading || !selectedAnimation || !selectedEffect
                            ? "To enable the cursor trail, select an effect and an animation type."
                            : "Enable the cursor trail to see the effect on your website."
                        }
                        inline
                      >
                        <ToggleSwitch
                          checked={isEnabled}
                          // disabled={
                          //   isLoading || !selectedAnimation || !selectedEffect
                          // }
                          onChange={() => {
                            if (
                              !(
                                isLoading ||
                                !selectedAnimation ||
                                !selectedEffect
                              )
                            ) {
                              if (isUpgraded) {
                                setIsEnabled(!isEnabled);
                              } else {
                                window.open(
                                  `https://www.wix.com/apps/upgrade/${"fd5f8619-81d6-44e0-ad18-46599d8f3d52"}?appInstanceId=${
                                    (instanceData as any)?.instance?.instanceId
                                  }`,
                                  "_blank"
                                );
                              }
                            }
                          }}
                        />
                      </Tooltip>
                    </FormField>
                    <Tabs
                      size="small"
                      activeId={selectedEffectType}
                      onClick={(value: any) => setSelectedEffectType(value.id)}
                      items={
                        // extract from meta.CursorShapes
                        Object.keys(meta.CursorShapes).map((key, index) => ({
                          id: index,
                          title: meta.CursorShapes[key].includes(
                            selectedEffect
                          ) ? (
                            <Badge
                              prefixIcon={<Icons.StatusCompleteFilledSmall />}
                              size="medium"
                              skin="neutralSuccess"
                            >
                              {key}
                            </Badge>
                          ) : (
                            key
                          ),
                        }))
                      }
                    />

                    <Layout>
                      {meta.CursorShapes[
                        Object.keys(meta.CursorShapes)[selectedEffectType || 0]
                      ].map((key: string) => (
                        <Cell span={4}>
                          <CardGalleryItem
                            backgroundImageNode={
                              <Image
                                src={getImageUrl(key)}
                                fit="contain"
                                width="100%"
                                height="100%"
                                transparent
                              />
                            }
                            title={
                              // convert from snake case to title case
                              key
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())
                            }
                            imagePlacement="side"
                            size="small"
                            primaryActionProps={{
                              onClick: () => setSelectedEffect(key),
                              label: "Select",
                            }}
                            suffix={
                              selectedEffect === key ? (
                                <Badge
                                  prefixIcon={
                                    <Icons.StatusCompleteFilledSmall />
                                  }
                                  skin="success"
                                ></Badge>
                              ) : undefined
                            }
                          ></CardGalleryItem>
                        </Cell>
                      ))}
                    </Layout>

                    <Tabs
                      size="small"
                      activeId={selectedAnimationType}
                      onClick={(value: any) =>
                        setSelectedAnimationType(value.id)
                      }
                      items={
                        // extract from meta.AnimationTypes
                        Object.keys(meta.AnimationTypes).map((key, index) => ({
                          id: index,
                          title: meta.AnimationTypes[key].includes(
                            selectedAnimation
                          ) ? (
                            <Badge
                              prefixIcon={<Icons.StatusCompleteFilledSmall />}
                              size="medium"
                              skin="neutralSuccess"
                            >
                              {key}
                            </Badge>
                          ) : (
                            key
                          ),
                        }))
                      }
                    />

                    <Layout>
                      {meta.AnimationTypes[
                        Object.keys(meta.AnimationTypes)[
                          selectedAnimationType || 0
                        ]
                      ].map((key: string) => (
                        <Cell span={4}>
                          <CardGalleryItem
                            backgroundImageNode={
                              // show if it is recommended
                              meta.BestCombinations[
                                Object.keys(meta.CursorShapes)[
                                  selectedEffectType
                                ]
                              ]?.includes(key) ? (
                                <Tooltip content="Recommended to pair with the selected effect">
                                  <Badge
                                    prefixIcon={<Icons.ThumbsUpFilledSmall />}
                                    size="medium"
                                    skin="neutralStandard"
                                  ></Badge>
                                </Tooltip>
                              ) : undefined
                            }
                            title={
                              // convert from snake case to title case
                              key
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())
                            }
                            imagePlacement="side"
                            size="small"
                            primaryActionProps={{
                              onClick: () => setSelectedAnimation(key),
                              label: "Select",
                            }}
                            suffix={
                              selectedAnimation === key ? (
                                <Badge
                                  prefixIcon={
                                    <Icons.StatusCompleteFilledSmall />
                                  }
                                  skin="success"
                                ></Badge>
                              ) : undefined
                            }
                          ></CardGalleryItem>
                        </Cell>
                      ))}
                    </Layout>

                    {/* <Card>
                      <Card.Content>
                        <CircularProgressBar
                          label={
                            isUpgraded
                              ? "You're on Premium"
                              : `You have ${trialRemainingDate} days left on your trial`
                          }
                          skin={isUpgraded ? "standard" : "premium"}
                          showProgressIndication
                          value={isUpgraded ? 100 : 1 - trialRemainingDate / 30}
                        />
                      </Card.Content>
                    </Card> */}
                    <Button
                      skin="premium"
                      as="a"
                      href={`https://www.wix.com/apps/upgrade/${"fd5f8619-81d6-44e0-ad18-46599d8f3d52"}?appInstanceId=${
                        (instanceData as any)?.instance?.instanceId
                      }`}
                      target="_blank"
                    >
                      {!isUpgraded
                        ? instanceData?.instance?.freeTrialAvailable
                          ? "Start a Trial"
                          : "Upgrade Now"
                        : "Manage Plan"}
                    </Button>
                    {isUpgraded && (
                      <Button
                        skin="inverted"
                        as="a"
                        href="https://bridget.reviews.certifiedcode.us/fd5f8619-81d6-44e0-ad18-46599d8f3d52"
                        target="_blank"
                      >
                        Rate Cursor Trail 5 stars
                      </Button>
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
